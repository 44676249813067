import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../../components/contact-info/contact-info";
import SEO from "../../../components/seo/seo";
import VimeoVideoFrame from "../../../components/custom-widgets/vimeo-video-frame";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import showMktoForm from "../../../helpers/showMktoForm";
import List from "../../../components/custom-widgets/list";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

const TreasuryExpress = () => {
  const title = "WaFd Bank Treasury Express - Online Treasury Management";
  const description =
    "Learn about WaFd Bank Treasury Express, a simplified online treasury management solution that is built for businesses of all sizes and complexities.";
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/express/hero-treasury-express-01232023-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/express/hero-treasury-express-01232023-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/express/hero-treasury-express-01232023-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/express/hero-treasury-express-01232023-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/express/hero-treasury-express-01232023-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/express/hero-treasury-express-01232023-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/express/hero-treasury-express-01232023-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/treasury-management-services/express"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-og-treasury-express-01232023.jpg"
      }
    ]
  };
  const heroChevronData = {
    id: "WAFD-Treasury-Express-hero",
    ...getHeroImgVariables(imgData),
    chevronBgClass: "bg-info",
    altText: "Businesswoman working on laptop at office desk",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Simplified Online Management of Accounts and Treasury"
          }
        },
        {
          id: 3,
          button: {
            id: "hero-cta-btn-1",
            class: "btn-white",
            onClick: () => showMktoForm(1067),
            type: "button",
            text: "Contact Us"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      url: "/commercial-banking/treasury-management-services",
      title: "Treasury Management"
    },
    {
      id: 3,
      active: true,
      title: "Express"
    }
  ];

  const prevListData = {
    accountName: "express-online-platform",
    items: [
      {
        id: 1,
        text: "Create &amp; manage sub-users"
      },
      {
        id: 2,
        text: "Check account balances and activity"
      },
      {
        id: 3,
        text: "Place stop payments"
      },
      {
        id: 4,
        text: "Transfer funds between accounts"
      },
      {
        id: 5,
        text: "View cleared check images"
      },
      {
        id: 6,
        text: "Access eStatements"
      },
      {
        id: 7,
        text: "Pay bills with small business bill pay"
      },
      {
        id: 8,
        text: "Make deposits"
      },
      {
        id: 9,
        text: "Access payment origination - ask about ACH and wire features (some limitations apply)"
      }
    ]
  };

  const videoData = {
    vimeoId: "399263384",
    videoTitle: "WAFD Treasury Express Demo",
    class: "iframe m-auto border-radius-12 w-100 border"
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>WAFD Treasury Express</h1>
        <div className="row">
          <div className="col-md-6 mb-3 mb-md-0">
            <VimeoVideoFrame {...videoData} />
          </div>
          <div className="col-md-6">
            <h3 className="text-success">
              Our suite of online solutions is built for businesses of all sizes and complexities. WAFD Treasury Express
              provides a simplified approach to online account management and treasury.
            </h3>
            <List {...prevListData} />
            <div className="row align-items-center">
              <div className="col-sm-auto col-md-12 col-lg-auto mb-3 mb-sm-0 mb-md-3 mb-lg-0">
                <Link
                  to="/commercial-banking/treasury-management-services/registration"
                  id="wafd-mobile-app-register-cta"
                  className="btn btn-link w-100 w-sm-auto"
                >
                  Learn How to Register Your App
                </Link>
              </div>
              <div className="col-sm col-md col-lg">
                <a
                  id="contact-commercial-banker-form-cta"
                  className="btn btn-primary w-100 w-sm-auto no-min-width"
                  href="/#"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    showMktoForm(1067);
                  }}
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ContactInfo type="commercial" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default TreasuryExpress;
